import React, { useEffect } from 'react';

import { useLocation } from '@reach/router';

import { ScContainer } from '../container/styled';

import { ScCardWrapper, ScTextWrapper, ScTitleForCardsWrapper } from './styled';

interface ITitleForCards {
	children: React.ReactNode;
}

export const TitleForCards = ({ children }: ITitleForCards) => {
	const location = useLocation();

	useEffect(() => {
		if (location.hash) {
			const elementId = location.hash.substring(1);
			const element = document.getElementById(elementId);
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
			}
		}
	}, [location]);

	return (
		<ScTitleForCardsWrapper>
			<ScContainer>
				<ScTextWrapper>
					<h1 className={'title'}>The perfect plan for any team</h1>
					<p className={'title-text'}>
						Our plans share the same best in class features and savings. The key difference is the
						onboarding and application approvals have been optimised for company size.
					</p>
				</ScTextWrapper>
				<ScCardWrapper id={'select-plan'}>{children}</ScCardWrapper>
			</ScContainer>
		</ScTitleForCardsWrapper>
	);
};
