import React from 'react';
import Slider from 'react-slick';
import { motion } from 'framer-motion';

import { MotionDiv } from '../../animations/components';
import { animationContainer, cardUp, fadeInLeft } from '../../animations/variants';
import useContentAnimation from '../../hooks/use-content-animation';
import { useMatchMedia } from '../../hooks/use-match-media';
import { ScContainer } from '../container/styled';
import { FeatureCard } from '../FeatureCard';

import {
	ScTestimonialsSliderDot,
	ScTestimonialsSliderWrapper,
	ScTestimonialsWrapper,
} from './styled';
import { featuresMonials } from './utils';

export const OneSlideCarousel = () => {
	const { ref, controls } = useContentAnimation();
	const isTablet = useMatchMedia(`(min-width: 768px)`);
	const settings = {
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: true,
		infinite: false,
		arrows: false,
		customPaging: function (i: number) {
			return <ScTestimonialsSliderDot key={i} />;
		},
		responsive: [
			{
				breakpoint: 1219,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<ScContainer>
			<motion.section initial="hidden" animate={controls} variants={animationContainer}>
				<ScTestimonialsWrapper>
					<ScTestimonialsSliderWrapper ref={ref}>
						<Slider {...settings}>
							{featuresMonials.map((featuresMonials) => (
								<MotionDiv
									key={featuresMonials.title}
									variants={isTablet ? cardUp : fadeInLeft}
									className="slider-wrappper"
								>
									<FeatureCard featuresMonials={featuresMonials} />
								</MotionDiv>
							))}
						</Slider>
					</ScTestimonialsSliderWrapper>
				</ScTestimonialsWrapper>
			</motion.section>
		</ScContainer>
	);
};
