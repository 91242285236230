import { motion } from "framer-motion";
import styled from "styled-components";

export const ScTitleForCardsWrapper = styled(motion.div)`
    text-align: center;
    margin-top: 70px;
    .title {
        color: ${({theme, color}) => color || theme.colors.black};
        font: ${({ theme }) => theme.fonts.heading.large};
        padding-left: 10px;
        padding-right: 10px;
        max-width: 1200px;
    }
    .title-text {
        margin-top: 40px;
        color: ${({theme, color}) => color || theme.colors.darkText};
        font: ${({ theme }) => theme.fonts.paragraph.large};
        padding-left: 10px;
        padding-right: 10px;
        max-width: 1200px;
    }
    @media (min-width: 280px) {
        .title {
            font: ${({ theme }) => theme.fonts.heading.small};
        }
        .title-text {
            font: ${({ theme }) => theme.fonts.paragraph.medium};
        }
    }
    @media (min-width: 400px) {
        .title {
            font: ${({ theme }) => theme.fonts.heading.medium};
        }
        .title-text {
            font: ${({ theme }) => theme.fonts.paragraph.medium};
        }
    }
    @media (min-width: 1220px) {
        .title {
            font: ${({ theme }) => theme.fonts.heading.large};
        }
        .title-text {
            font: ${({ theme }) => theme.fonts.paragraph.large};
        }
    }
`;

export const ScCardWrapper = styled(motion.div)`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 100px;
    justify-content: space-between;
    div:first-child {
        margin-right: 20px;
    }
    div:last-child {
        margin-left: 20px;
    }

    @media (max-width: 1220px) {
        div:first-child {
            margin-right: 0;
        }
        div:last-child {
            margin-left: 0;
        }
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-around;
        align-items: center;
        div:last-child {
            margin-top: 20px;
        }
    }
`;

export const ScTextWrapper = styled(motion.div)`
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;

