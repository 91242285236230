import React from 'react';
import { useTheme } from 'styled-components';

import { isNotSSR } from '@utils';

import { CustomButton } from '../custom-button';

import { ScCardPlanWrapper, ScContentWrapper, ScImageWrapper } from './styled';

interface ICardPlan {
	img: string;
	altImg: string;
	label: string;
	title: string;
	text: string;
	buttonRedirectUrl: string;
}

export const CardPlan = ({ img, altImg, label, title, text, buttonRedirectUrl }: ICardPlan) => {
	const theme = useTheme();
	const handleClick = () => {
		if (isNotSSR) {
			window.location.href = buttonRedirectUrl;
		}
	};

	return (
		<ScCardPlanWrapper>
			<ScImageWrapper>
				<img src={img} alt={altImg} className={'label-img'} />
			</ScImageWrapper>
			<ScContentWrapper>
				<h1>{label}</h1>
				<h2>{title}</h2>
				<p>{text}</p>
			</ScContentWrapper>
			<CustomButton
				color={theme.colors.white}
				background={theme.colors.darkText}
				border={theme.colors.darkText}
				hoverBackground={theme.colors.marmelade}
				hoverColor={theme.colors.darkText}
				font={`${theme.fonts.button.mediumSecond} !important`}
				onClick={handleClick}
			>
				<>
					Sign up <span>❯</span>
				</>
			</CustomButton>
		</ScCardPlanWrapper>
	);
};
