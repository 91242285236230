export interface IFeaturesMonials {
	text: string;
	title: string;
}

export const featuresMonials: IFeaturesMonials[] = [
	{
		text: 'We work with the very best retailers and brands. Because bike shops love Gogeta vouchers, they can even be used on sale bikes, with no surcharges, unlocking the best deals.',
		title: 'Access to the best bikes, retailers and deals',
	},
	{
		text: 'Unlike other schemes, retailers don’t charge a surcharge for using gogeta vouchers. Our end of hire transfer of ownership fee (an HMRC requirement) is just £1, unlike other providers who charge as much as 7-15% of the bike cost.',
		title: 'No high surcharges or end of term fees',
	},
	{
		text: 'The most seamless cycle to work scheme ever. Apply for a voucher, approve and redeem, all on the same platform.',
		title: 'Easy set up and application',
	},
];
