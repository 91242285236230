import styled from 'styled-components';

export const ScTestimonialsSliderWrapper = styled.div`
	width: 100%;
	height: 511px;
	gap: 100px;
	border-radius: 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: gray;
	margin-top: 100px;
	margin-bottom: 100px;
	background: ${({ theme }) => theme.colors.wheat};
	.slick-slider {
		margin: 0 15px;
		@media (min-width: 1220px) {
		}
	}
	.slick-list {
		margin: 0 -10px;
	}
	.slick-dots {
		bottom: 0;
	}
	.slick-dots li {
		width: 18px;
		height: 5px;
		border-radius: 10px;
		cursor: pointer;
		background: ${({ theme }) => theme.colors.darkText};
		margin: 0 4px;
		@media (min-width: 1220px) {
			background: ${({ theme }) => theme.colors.darkText};
			width: 24px;
			height: 6px;
			margin: 0 12px;
		}
	}
	.slick-dots .slick-active div {
		background: ${({ theme }) => theme.colors.marmelade};
	}
	.slider-wrappper {
		height: 100%;
		display: flex;
		justify-content: center;
	}
`;

export const ScTestimonialsSliderDot = styled.div`
	width: 18px;
	height: 5px;
	border-radius: 10px;
	cursor: pointer;
	background: ${({ theme }) => theme.colors.darkText};
	@media (min-width: 1220px) {
		background: ${({ theme }) => theme.colors.darkText};
		width: 24px;
		height: 6px;
	}
`;

export const ScTestimonialsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 25px;
	align-items: center;
	@media (min-width: 768px) {
	}
	@media (min-width: 1220px) {
		gap: 100px;
	}

	.slick-list {
		display: flex;
		width: calc(100% + 20px);
	}
	.slick-track {
		display: flex;
		flex-direction: row;
	}
	.slick-slide {
		> div {
			height: 100%;
		}
	}
`;
