import React from 'react';

import { IFeaturesMonials } from '../OneSlideCarousel/utils';

import { ScTestimonialCard, ScTestimonialInfo } from './styled';

export const FeatureCard = ({ featuresMonials }: { featuresMonials: IFeaturesMonials }) => {
	return (
		<ScTestimonialCard>
			<ScTestimonialInfo>
				<h6>{featuresMonials.title}</h6>
				<p>{featuresMonials.text}</p>
			</ScTestimonialInfo>
		</ScTestimonialCard>
	);
};
