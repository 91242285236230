import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ScCardPlanWrapper = styled(motion.div)`
	background: ${({ theme, color }) => color || theme.colors.lightShadeOfBrown};
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	align-items: center;
	max-height: 790px;
	height: inherit;
	padding: 80px 60px 80px 60px;
	border-radius: 30px;
	@media (min-width: 280px) {
		padding: 60px 30px 60px 30px;
		max-width: none;
		width: auto;
		.label-img {
			height: 220px;
		}
	}
	@media (min-width: 768px) {
		padding: 60px 30px 60px 30px;
		max-width: 660px;
		width: 100%;
	}
	@media (min-width: 1220px) {
		padding: 60px 30px 60px 30px;
	}
	button {
		transition: 0.2s linear;
		cursor: pointer;
		width: 133px;
		height: 44px;
		padding: 12px 22px 12px 22px;
		gap: 10px;
		border-radius: 22px;
		font: ${({ theme }) => theme.fonts.card.buttonLarge};
		img {
			padding-left: 10px;
		}
	}
	span {
		padding-left: 5px;
	}
`;

export const ScContentWrapper = styled(motion.div)`
	min-height: 262px;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	align-items: center;
	max-width: 440px;
	text-align: center;
	margin-top: 60px;
	margin-bottom: 15px;
	h1 {
		color: ${({ theme, color }) => color || theme.colors.black};
	}
	h2 {
		color: ${({ theme, color }) => color || theme.colors.darkText};
		margin-top: 20px;
	}
	p {
		color: ${({ theme, color }) => color || theme.colors.darkText};
		margin-top: 20px;
	}
	@media (min-width: 280px) {
		h1 {
			font: ${({ theme }) => theme.fonts.card.labelMedium};
		}
		h2 {
			font: ${({ theme }) => theme.fonts.card.titleMedium};
		}
		p {
			font: ${({ theme }) => theme.fonts.card.textMedium};
		}
	}
	@media (min-width: 768px) {
		h1 {
			font: ${({ theme }) => theme.fonts.card.labelLarge};
		}
		h2 {
			font: ${({ theme }) => theme.fonts.card.titleLarge};
		}
		p {
			font: ${({ theme }) => theme.fonts.card.textLarge};
		}
	}
	@media (min-width: 1220px) {
		h1 {
			font: ${({ theme }) => theme.fonts.card.labelMedium};
		}
		h2 {
			font: ${({ theme }) => theme.fonts.card.titleMedium};
		}
		p {
			font: ${({ theme }) => theme.fonts.card.textMedium};
		}
	}
	@media (min-width: 1920px) {
		h1 {
			font: ${({ theme }) => theme.fonts.card.labelLarge};
		}
		h2 {
			font: ${({ theme }) => theme.fonts.card.titleLarge};
		}
		p {
			font: ${({ theme }) => theme.fonts.card.textLarge};
		}
	}
`;

export const ScImageWrapper = styled(motion.div)`
	@media (min-width: 280px) {
		img {
			height: 220px;
		}
	}
	@media (min-width: 768px) {
		img {
			height: 271px;
		}
	}
`;
