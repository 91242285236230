import styled from 'styled-components';

export const ScTestimonialCard = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	border-radius: 10px;
	box-sizing: border-box;
	padding-bottom: 20px;
	height: 100%;
	max-width: 590px;
	svg {
		width: 175px;
		height: auto;
		@media (min-width: 1220px) {
			width: 250px;
		}
	}
	@media (min-width: 1220px) {
		border-radius: 30px;
		padding: 30px;
		gap: 35px;
	}
`;

export const ScTestimonialInfo = styled.div`
	text-align: center;
	h6 {
		margin-bottom: 10px;
		@media (min-width: 280px) {
			font: ${({ theme }) => theme.fonts.heading.xs};
		}
		@media (min-width: 768px) {
			font: ${({ theme }) => theme.fonts.heading.small};
		}
	}
	p {
		@media (min-width: 280px) {
			font: ${({ theme }) => theme.fonts.card.textMedium};
		}
		@media (min-width: 768px) {
			font: ${({ theme }) => theme.fonts.card.textLarge};
		}
	}
`;
